import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

const EventList = React.lazy(() => import('../components/IHO/Event/List'));
const EventShow = React.lazy(() => import('../components/IHO/Event/Show'));
const EventAdd = React.lazy(() => import('../components/IHO/Event/Add'));
const EventModify = React.lazy(() => import('../components/IHO/Event/Modify'));
const AttendeeList = React.lazy(() => import('../components/IHO/Attendee/List'));
const AttendeeShow = React.lazy(() => import('../components/IHO/Attendee/Show'));
const AttendeeManagement = React.lazy(() => import('../components/IHO/Event/Management'));

const Calendar = React.lazy(() => import('../components/IHO/Calendar'));

const PDF = React.lazy(() => import('../components/IHO/Other/PDF'));

const Statistics = React.lazy(() => import('../components/IHO/Statistics'));
const Organizations = React.lazy(() => import('../components/IHO/Organizations'));

const TotalMAil = React.lazy(() => import('../components/IHO/Mail'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/manager/event" />,
    route: PrivateRoute,
};

const eventAppRoutes = {
    path: '/manager/event',
    name: 'Event',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-calender',
    component: EventList,
    header: 'Manger',
};

const attendeeRoutes = {
    path: '/manager/attendee',
    name: 'Attendee',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-comments-alt',
    component: AttendeeList,
};

const calendarAppRoutes = {
    path: '/calendar',
    name: 'Calendar',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-calender',
    component: Calendar,
    header: 'Apps',
};

const PDFFuntionRoutes = {
    path: '/event',
    name: 'Event show',
    component: PDF,
    route: PrivateRoute,
};

const StatisticsRoutes = {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    route: PrivateRoute,
};

const OrganizationRoutes = {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
    route: PrivateRoute,
};

const TotalMAilRoutes = {
    path: '/mail',
    name: 'TotalMAil',
    component: TotalMAil,
    route: PrivateRoute,
};

const appRoutes = [eventAppRoutes, attendeeRoutes];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

const eventFunctionRoutes = {
    path: '/event',
    name: 'event function',
    children: [
        {
            path: '/manager/event.show',
            name: 'event show',
            component: EventShow,
            route: PrivateRoute,
        },
        {
            path: '/manager/event.add',
            name: 'event add',
            component: EventAdd,
            route: PrivateRoute,
        },
        {
            path: '/manager/event.modify',
            name: 'event modify',
            component: EventModify,
            route: PrivateRoute,
        },
        {
            path: '/manager/attendee.show',
            name: 'attendee show',
            component: AttendeeShow,
            route: PrivateRoute,
        },
        {
            path: '/manager/attendee.management',
            name: 'attendee management',
            component: AttendeeManagement,
            route: PrivateRoute,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    ...appRoutes,
    authRoutes,
    eventFunctionRoutes,
    calendarAppRoutes,
    PDFFuntionRoutes,
    StatisticsRoutes,
    OrganizationRoutes,
    TotalMAilRoutes,
];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
